import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import {
    Component, EventEmitter, Input, OnInit, Output, TemplateRef,
    ViewChild
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuDefinition } from '../menu/menu-definition';
import { MenuService } from '../menu/menu.service';
import { NotificacoesService } from 'src/app/core/service/notificacoes.service';
import { Notificacao } from 'src/app/core/models/notificacao.model';
import { StatusNotificacao } from 'src/app/core/models/status-notificacao.model';
import { Observable, Subject } from 'rxjs';
import { ComponentType } from 'ngx-toastr';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    @Input()
    menuItens: MenuDefinition[];

    @Input()
    appName: string;

    @Input()
    appDescription: string;

    @Input()
    darkThemeClass: string;

    @Input()
    usuario: string;
    
    @Input()
    contatoInfo = '';

    @Input()
    sobreInfo = '';

    @Input()
    versaoFront = '';

    @Input()
    versaoApi = '';

    @Output()
    logout = new EventEmitter();

    isHandset = true;
    isDarkTheme = false;

    showNotifications: boolean = false;

    notifications: Notificacao[] = [];

    @ViewChild('dialog')
    dialogTemplateRef = {} as TemplateRef<any>;
    dialogRef: MatDialogRef<any>;
    confirmMessage: string;

    actualNotificacao: Notificacao;
    tituloNotificacao: string;

    constructor(
        private notificacoesService: NotificacoesService,
        private menuService: MenuService,
        private overlayContainer: OverlayContainer,
        private breakpointObserver: BreakpointObserver,
        private dialog: MatDialog,
        private router: Router
    ) {

        this.navigateHome();
       
    }

    toggleTheme(): void {
        this.isDarkTheme = !this.isDarkTheme;
        const classList = this.overlayContainer.getContainerElement().classList;
        if (this.isDarkTheme) {
            classList.add(this.darkThemeClass);
        } else {
            classList.remove(this.darkThemeClass);
        }
    }

    toggleNotifications() {
        this.showNotifications = !this.showNotifications;
      }

    navigateHome(){
        this.router.navigate(['/inicio']);
    }

    toggleSidebarState(): void {
        this.menuService.toggleState();
    }

    ngOnInit(): void {
        this.recoveryNotitifications();
        this.breakpointObserver
            .observe([Breakpoints.Handset])
            .subscribe((result) => {
                this.isHandset = result.matches;
                if (this.isHandset) {
                    this.menuService.setExpanded(false);
                }
            });
    }

    openDialog(dialogRef: TemplateRef<any>): void {
        this.dialog.open(dialogRef, { ariaLabel: 'Fechar' });
    }

    onLogout(): void {
        this.logout.emit();
    }

    openNotificationModal(notificacao: Notificacao): void {
        console.log(notificacao.mensagem);
        this.actualNotificacao = notificacao;
        this.tituloNotificacao = notificacao.titulo;
        let msg = `${notificacao.mensagem}`;
        this.msgNotificacao(msg);
    }

    msgNotificacao(msg: string): void {
        this.confirmDialog(msg).subscribe(() => {
        });
    }

    visualizar(): void {
        let notificacao = this.actualNotificacao;
        this.notificacoesService.updateStatusNotificacao(notificacao.id, new StatusNotificacao(true, null)).subscribe(
            result => {this.recoveryNotitifications();}
        )
    }

    excluir(): void {
        let notificacao = this.actualNotificacao;
        this.notificacoesService.updateStatusNotificacao(notificacao.id, new StatusNotificacao(null, true)).subscribe(
            result => {this.recoveryNotitifications();}
        )
    }

    desabilitarBotaoVisualizar(): boolean {
        let notificacao = this.actualNotificacao;
        if(notificacao.resposta.length === 0) {
            return false;
        }
        if(notificacao.resposta.length > 0) {
            return notificacao.resposta.find(r => r.isVisualizado === true).isVisualizado;
        }
    }

    desabilitarBotaoExcluir(): boolean {
        let notificacao = this.actualNotificacao;
        if(notificacao.resposta.length === 0) {
            return true;
        }
        if(notificacao.resposta.length > 0) {
            return !notificacao.resposta.find(r => r.isVisualizado === true).isVisualizado;
        }
    }

    exibirContador(): boolean {
        return this.notifications.filter(n => n.resposta.length === 0).length > 0;
    }

    qtdContador(): number {
        return this.notifications.filter(n => n.resposta.length === 0).length;
    }

    private recoveryNotitifications(): void {
        this.notificacoesService.getNotificacao().subscribe(
            result => {this.notifications = result}
        )
    }

    confirmDialog(message: string): Observable<void> {
        this.confirmMessage = message;
        return this.openDialog2(this.dialogTemplateRef, { width: '400px' });
    }

    private openDialog2(reference: ComponentType<any> | TemplateRef<any>, options: MatDialogConfig<any>): Observable<void> {
        let subject = new Subject<void>();
        this.dialogRef = this.dialog.open(reference, options);
        this.dialogRef.afterClosed().subscribe(data => {
            if (data) {
                subject.next();
                subject.complete();
            }
        });
        return subject;        
    }
}
