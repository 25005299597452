import { Injectable } from '@angular/core';
import { ApiService } from '../service/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PagedCollectionResponse } from './models/collection-response.model';
import { Notificacao } from '../models/notificacao.model';
import { StatusNotificacao } from '../models/status-notificacao.model';

@Injectable({
    providedIn: 'root',
})
export class NotificacoesService {
    constructor(private api: ApiService, private http: HttpClient) {}

    save(notificacao: Notificacao): Observable<any> {
        return this.api.getApi().pipe(
            switchMap((api) => {
                    return this.http.post(
                        api._links.createNotificacao.href,
                        notificacao
                    );
            } )
        )
}

    findAllNotificacao(page: number, size: number, sort: string, order: string): Observable<PagedCollectionResponse<Notificacao>> {
        return this.api.getApi().pipe(
            switchMap(api => 
                this.http.get<PagedCollectionResponse<Notificacao>>(
                    api._links.findAllNotificacao.href,
                    {
                        params: new HttpParams()
                            .append('sort', `${sort},${order}`)
                            .append('page', String(page))
                            .append('size', String(size)),
                    }
                )
            )
        );
    }


    getNotificacao(): Observable<Notificacao[]> {
        return this.api.getApi().pipe(
            switchMap(api => 
                this.http.get<Notificacao[]>(
                    api._links.getNotificacao.href
                )
            )
        );
    }

    desativarNotificacao(id: number) {
        return this.api.getApi().pipe(
            switchMap((api) => {
                const url = api._links.desativarNotificacao.href.replace("{id}", `${id}`);
                return this.http.patch(url, {});
            })
        );
    }

    ativarNotificacao(id: number) {
        return this.api.getApi().pipe(
            switchMap((api) => {
                const url = api._links.ativarNotificacao.href.replace("{id}", `${id}`);
                return this.http.patch(url, {});
            })
        );
    }

    updateStatusNotificacao(id: number, statusNotificacao: StatusNotificacao) {
        return this.api.getApi().pipe(
            switchMap((api) => {
                const url = api._links.updateStatusNotificacao.href.replace("{id}", `${id}`);
                return this.http.put(url, statusNotificacao);
            })
        );
    }
}